import styled from "styled-components";
import { DEVICE } from "../../utilities/constants";

export const Parent = styled.div`
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 32px #2477b314;
  border-radius: 12px;
  opacity: 1;
  margin-left: 40px;
`;

export const SubParent = styled.div`
  display: flex;
`;

export const LeftSection = styled.div`
  width: 60%;
  padding: 20px;
`;

export const RightSection = styled.div`
  width: 40%;
  padding: 15px;
`;

export const DataSection = styled.div`
  padding: 10px 40px 0 0;
  border-right: 1px solid #d7eff7;
`;

export const Datarow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  font-size: 14px;
  color: #404040;
  border-bottom: 1px solid #d7eff7;
`;

export const PlanHeader = styled.div`
  font-size: 30px;
  border-bottom: 1px solid #d7eff7;
  color: #0099cc;
  margin-bottom: 20px;
  padding: 20px;
`;

export const DataValue = styled.div`
  background: #ebf7fb;
  border: 1px solid #d7eff7;
  border-radius: 5px;
  padding: 4px 8px;
  color: #0099cc;
`;

export const ContactCard = styled.div`
  width: 394px;
  height: 192px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  background: #0099cc;
  border-radius: 12px 12px 0px 0px;
  height: 59px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  padding-left: 40px;
  text-transform: uppercase;
  font-size: 14px;
`;

export const Details = styled.div`
  background: #ebf7fb;
  border-radius: 0px 0px 12px 12px;
  padding: 20px 20px 30px 40px;
`;

export const DetailText = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #404040;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const DetailName = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: #0099cc;
`;

export const DatarowHeader = styled.p`
  font-size: 16px;
  color: #0099cc;
`;

export const ManagerDetails = styled.img`
  height: 12px;
  @media ${DEVICE.tablet} {
    height: 12px;
  }
`;
