import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUpcomingCamps } from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getUpcomingCampsAction = createAsyncThunk(
  "upcoming-camps",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching upcoming camps...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getUpcomingCamps();
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching camps",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching camps");
    }
  }
);
