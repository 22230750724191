import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getServicePlan = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.SERVICE_PLAN);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getManager = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.COMPANY_PLAN_DETAILS);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export { getManager, getServicePlan };
