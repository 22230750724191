import { Box, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IDownArrow, ILogout } from "../../../../assets";

import useIsAuthenticated from "../../../../hooks/useIsAuthenticated";
import { signoutAction } from "../../../../store/actions";
import { authSelector } from "../../../../store/slice/auth.slice";
import { COLOR_VARIANT } from "../../../../utilities/constants";
import { UserDetails } from "./style.components";

const ProfileDropdown = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const state = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    handleClose();
    await dispatch(signoutAction());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <UserDetails onClick={handleClick}>
        {state?.userDetails?.company_logo ? (
          <img
            src={state?.userDetails?.company_logo}
            style={{
              borderRight: `1px solid #D3D3D3`,
              paddingRight: "10px",
              width: 70,
            }}
            alt="Company Icon"
          />
        ) : null}

        <div
          style={{
            color: COLOR_VARIANT,
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#2477B3",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span>{state?.userDetails?.company_name || ""}</span>{" "}
            <img
              src={IDownArrow}
              height={5}
              alt="down-arrow"
              style={{ marginLeft: 15 }}
            />
          </div>
        </div>
      </UserDetails>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ color: "#808080", fontSize: "16px", borderRadius: "8px" }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${COLOR_VARIANT}`,
            padding: "5px 15px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <img width={20} src={ILogout} alt="Logout Icon" />
          <Typography sx={{ p: 1, color: "#808080", fontSize: "16px" }}>
            Logout
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default ProfileDropdown;