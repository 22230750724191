import { createSlice } from "@reduxjs/toolkit";
import {
  addEmployeeAction,
  deleteEmployeeByIdAction,
  getEmployeeByNumberAction,
  getEmployeesAction,
  getTotalActiveEmployeesCountAction,
  getTotalEmployeesCountAction,
  updateEmployeeByIdAction,
} from "../actions";

const initialState = {
  isEmployeeAdded: null,
  isLoading: null,
  totalEmployee: null,
  totalActiveEmployee: null,
  employees: null,
  isEmployeeDeleted: null,
  isEmployeeUpdated: null,
  searchedEmployee: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //add employees
    builder.addCase(addEmployeeAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addEmployeeAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isEmployeeAdded = true;
    });
    builder.addCase(addEmployeeAction.rejected, (state) => {
      state.isLoading = false;
      state.isEmployeeAdded = false;
    });
    // total employees
    builder.addCase(getTotalEmployeesCountAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getTotalEmployeesCountAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.totalEmployee = payload?.result?.[0]?.total;
      }
    );
    builder.addCase(getTotalEmployeesCountAction.rejected, (state) => {
      state.isLoading = false;
      state.totalEmployee = null;
    });
    //total active employees
    builder.addCase(getTotalActiveEmployeesCountAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getTotalActiveEmployeesCountAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.totalActiveEmployee = payload?.result?.[0]?.total;
      }
    );
    builder.addCase(getTotalActiveEmployeesCountAction.rejected, (state) => {
      state.isLoading = false;
      state.totalActiveEmployee = null;
    });
    //get employees
    builder.addCase(getEmployeesAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEmployeesAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employees = payload?.result;
      state.isEmployeeDeleted = null;
      state.isEmployeeUpdated = null;
    });
    builder.addCase(getEmployeesAction.rejected, (state) => {
      state.isLoading = false;
      state.employees = null;
    });
    //get employee by number
    builder.addCase(getEmployeeByNumberAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getEmployeeByNumberAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.searchedEmployee = payload?.result || null;
      }
    );
    builder.addCase(getEmployeeByNumberAction.rejected, (state) => {
      state.isLoading = false;
      state.employees = null;
      state.searchedEmployee = null;
    });
    //delete employee by id
    builder.addCase(deleteEmployeeByIdAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteEmployeeByIdAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isEmployeeDeleted = true;
    });
    builder.addCase(deleteEmployeeByIdAction.rejected, (state) => {
      state.isLoading = false;
      state.isEmployeeDeleted = false;
    });
    //update employee by id
    builder.addCase(updateEmployeeByIdAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmployeeByIdAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isEmployeeUpdated = true;
    });
    builder.addCase(updateEmployeeByIdAction.rejected, (state) => {
      state.isLoading = false;
      state.isEmployeeUpdated = false;
    });
  },
});

export const employeeSelector = (state) => state.employee;
export default employeeSlice.reducer;
