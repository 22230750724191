import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IEmployeeAdded, IPlanIncludes } from "../../../assets";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import { getBasicStatsAction } from "../../../store/actions/dashboard.action";
import { dashboardSelector } from "../../../store/slice/dashboard.slice";
import { Loader } from "../../shared";
import {
  BasicInfo,
  BasicInfoWrapper,
  CountImage,
  CountLabel,
  CountValue,
  InfoWrapper,
  SingleCountBlock,
  SingleCountBlockDetails,
} from "./style.components";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CountBlock = ({ icon, label, count, color, borderRight }) => {
  return (
    <SingleCountBlock borderRight={borderRight}>
      <div style={{ marginRight: "20px" }}>
        <CountImage src={icon} />
      </div>
      <SingleCountBlockDetails>
        <CountLabel>{label}</CountLabel>
        <CountValue style={{ color: color }}>{count}</CountValue>
      </SingleCountBlockDetails>
    </SingleCountBlock>
  );
};

const BasicCount = () => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, basicStatsData } = useSelector(dashboardSelector);

  const fetchBasicStats = useCallback(async () => {
    await dispatch(getBasicStatsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      fetchBasicStats();
    }
  }, [navigate, isAuthenticated, fetchBasicStats]);

  return (
    <InfoWrapper>
      <BasicInfoWrapper>
        <BasicInfo>
          {!isLoading ? (
            <>
              {basicStatsData?.[0]?.plan_size ? (
                <CountBlock
                  color={"#CC8552"}
                  icon={IPlanIncludes}
                  label={"Plan Includes"}
                  count={numberWithCommas(basicStatsData?.[0]?.plan_size)}
                  borderRight={true}
                />
              ) : null}
              {basicStatsData?.[0]?.employee_count ? (
                <CountBlock
                  color={"#7ABA56"}
                  icon={IEmployeeAdded}
                  label={"Employees Added"}
                  count={numberWithCommas(basicStatsData?.[0]?.employee_count)}
                  borderRight={true}
                />
              ) : null}
              {/* {basicStatsData?.[0]?.employee_count ? (
                <CountBlock
                  color={"#0099CC"}
                  icon={IEmployeeRegistered}
                  label={"Employees Registered"}
                  count={numberWithCommas(basicStatsData?.[0]?.employee_count)}
                  currency={true}
                />
              ) : null} */}
            </>
          ) : (
            <Loader height="100px" />
          )}
        </BasicInfo>
      </BasicInfoWrapper>
    </InfoWrapper>
  );
};

export default BasicCount;
