import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 32px #2477b314;
  border-radius: 12px;
  opacity: 1;
  margin-left: 40px;
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const Header = styled.div`
  font-size: 30px;
  border-bottom: 1px solid #d7eff7;
  color: #0099cc;
  margin-bottom: 20px;
  padding: 20px;
`;
export const LeftSection = styled.div`
  width: 55%;
  margin-right: 20px;
  margin-left: 20px;
`;
export const RightSection = styled.div`
  width: 35%;
`;
export const SubHeader = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: 600;
`;
export const Label = styled.label`
  font-size: 14px;
  color: #404040;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
export const MobileInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const TextInput = styled.input`
  background: #ebf7fb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px 15px;
  width: 400px;
  height: 35px;
  margin-top: 10px;
  border-style: hidden;
  font-size: 14px;
  outline: none;
`;
export const MobilePlaceholder = styled.div`
  width: 40px;
  height: 35px;
  padding: 5px;
  font-size: 14px;
  background: #ebf7fb 0% 0% no-repeat padding-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: 10px;
  border-style: hidden;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const SubmitButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 50px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
`;
export const ErrorMessage = styled.label`
  color: #e40000;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Message = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
`;
