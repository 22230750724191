import React from "react";
import { IAdd, IEditIcon } from "../../../assets";
import {
  Employees,
  Link,
  Separator,
  SideNavbar,
  SubLink,
} from "./style.components";

const SideNav = () => {
  return (
    <SideNavbar>
      <Link to="/home">HOME</Link>
      <Separator />
      <Link to="#">EMPLOYEES</Link>
      <SubLink to="/add-employee">
        <Employees src={IAdd} />
        Add Employees
      </SubLink>
      <SubLink to="/manage-employees">
        <Employees src={IEditIcon} />
        Manage Employees
      </SubLink>
      <Separator />
      <Link to="/upcoming-camps">UPCOMING CAMPS</Link>
      <Separator />
      <Link to="/service-plan">SERVICE PLAN</Link>
    </SideNavbar>
  );
};

export default SideNav;
