import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ManagerInfo } from "../../../components/layout";
import { SnackBar } from "../../../components/shared";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import { addEmployeeAction } from "../../../store/actions/employee.action";
import { getManagerAction } from "../../../store/actions/service-plan.action";
import { employeeSelector } from "../../../store/slice/employee.slice";
import { servicePlanSelector } from "../../../store/slice/service-plan.slice";
import {
  Container,
  ErrorMessage,
  Header,
  InputWrapper,
  Label,
  LeftSection,
  Message,
  MobileInputWrapper,
  MobilePlaceholder,
  RightSection,
  SubHeader,
  SubmitButton,
  TextInput,
  Wrapper,
} from "./style.components";

const AddEmployee = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { managerData } = useSelector(servicePlanSelector);
  const { isEmployeeAdded } = useSelector(employeeSelector);

  const [name, setName] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    mobileNumber: "",
  });

  let countryCodes;
  countryCodes = ['+91','+44','+1','+590','+297','+599','+53','+509','+299','+692','+596','+52','+691','+508','+685','+670'];

  const fetchManager = useCallback(async () => {
    await dispatch(getManagerAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      fetchManager();
    }
  }, [fetchManager, navigate, isAuthenticated]);

  const onAddEmployee = async () => {
    if (!name) {
      setErrorMessage({
        ...errorMessage,
        name: "Name is required",
      });
      return;
    }
    if (!mobileNumber) {
      setErrorMessage({
        ...errorMessage,
        mobileNumber: "Mobile number is required",
      });
      return;
    }
    const data = {
      name,
      number: `${selectedCountryCode}${mobileNumber}`,
      email: emailId,
    };
    await dispatch(addEmployeeAction(data));
  };

  useEffect(() => {
    if (isEmployeeAdded) {
      setName("");
      setEmailId("");
      setMobileNumber("");
      setErrorMessage({ name: "", mobileNumber: "" });
    }
  }, [isEmployeeAdded]);

  return (
    <Container>
      <Header>Add Employees</Header>
      <Wrapper>
        <LeftSection>
          <SubHeader>Employee Details</SubHeader>
          <InputWrapper>
            <Label>Name</Label>
            <TextInput
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage({
                  ...errorMessage,
                  name: "",
                });
              }}
            />
            {errorMessage?.name && (
              <ErrorMessage>{errorMessage?.name}</ErrorMessage>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label>Mobile No.</Label>
            <MobileInputWrapper>
              <MobilePlaceholder
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.target.value)}
              >
                {countryCodes.map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </MobilePlaceholder>
              <TextInput
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                  setErrorMessage({
                    ...errorMessage,
                    mobileNumber: "",
                  });
                }}
                type="number"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  width: 365,
                  paddingLeft: '5px',
                }}
              />
            </MobileInputWrapper>
            {errorMessage?.mobileNumber && (
              <ErrorMessage>{errorMessage?.mobileNumber}</ErrorMessage>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label>Email-Id (Optional)</Label>
            <TextInput
              type="email"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
            />
          </InputWrapper>
          <SubmitButton onClick={onAddEmployee}>Add</SubmitButton>
        </LeftSection>
        <RightSection>
          <SubHeader>Bulk Upload</SubHeader>
          <Message style={{ marginTop: 20, marginBottom: 20 }}>
            For bulk upload, contact your relationship manager
          </Message>
          <ManagerInfo
            managerEmail={managerData?.[0]?.manager_email}
            managerName={managerData?.[0]?.manager_name}
            managerNumber={managerData?.[0]?.manager_number}
          />
        </RightSection>
      </Wrapper>
      <SnackBar />
    </Container>
  );
};

export default AddEmployee;
