import { createSlice } from "@reduxjs/toolkit";
import { getBasicStatsAction, getScheduledCampsActions } from "../actions";

const initialState = {
  basicStatsData: null,
  scheduledCampsData: null,
  isLoading: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBasicStatsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBasicStatsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.basicStatsData = payload?.result;
    });
    builder.addCase(getBasicStatsAction.rejected, (state) => {
      state.isLoading = false;
      state.basicStatsData = null;
    });
    builder.addCase(getScheduledCampsActions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getScheduledCampsActions.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.scheduledCampsData = payload?.result;
      }
    );
    builder.addCase(getScheduledCampsActions.rejected, (state) => {
      state.isLoading = false;
      state.scheduledCampsData = null;
    });
  },
});

export const dashboardSelector = (state) => state.dashboard;
export default dashboardSlice.reducer;
