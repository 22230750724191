import { ILogoWhite } from "../../../assets";
import ProfileDropdown from "./profile-dropdown";
import { LogoImage, LogoImageCont, StickyHeader } from "./style.components";

const FixedHeader = () => {
  return (
    <>
      <StickyHeader>
        <LogoImageCont>
          <LogoImage src={ILogoWhite} />
        </LogoImageCont>
        <ProfileDropdown />
      </StickyHeader>
    </>
  );
};

export default FixedHeader;
