import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../../utilities/constants";

export const SideNavbar = styled.div`
  width: 20%;
  padding: 20px;
  color: #0099cc;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 32px #2477b314;
  border-radius: 12px;
  opacity: 1;
`;

export const Link = styled(RouterLink)`
  display: block;
  height: 70px;
  line-height: 70px;
  color: #0099cc;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #d7eff7;
`;

export const SubLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 50px;
  line-height: 70px;
  color: #404040;
  text-decoration: none;
  padding-left: 20px;
  cursor: pointer;
  font-size: 14px;
`;

export const Employees = styled.img`
  height: 16px;
  @media ${DEVICE.tablet} {
    height: 16px;
  }
`;
