import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { ManagerInfo } from "../../components/layout";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import {
  getManagerAction,
  getServicePlanAction,
} from "../../store/actions/service-plan.action";
import { servicePlanSelector } from "../../store/slice/service-plan.slice";
import {
  DataSection,
  DataValue,
  Datarow,
  DatarowHeader,
  LeftSection,
  Parent,
  PlanHeader,
  RightSection,
  SubParent,
} from "./style.components";

const ServicePLan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { servicePlanData, managerData } = useSelector(servicePlanSelector);

  const fetchServicePlans = useCallback(async () => {
    await dispatch(getServicePlanAction());
  }, [dispatch]);

  const fetchManager = useCallback(async () => {
    await dispatch(getManagerAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      // navigate("/login");
    } else {
      fetchServicePlans();
      fetchManager();
    }
  }, [fetchManager, fetchServicePlans, navigate, isAuthenticated]);

  const consultationServices = servicePlanData?.filter(
    (item) => item?.service_type?.toString()?.toLowerCase() === "consultation"
  );
  const testServices = servicePlanData?.filter(
    (item) => item?.service_type?.toString()?.toLowerCase() === "test"
  );

  return (
    <Parent>
      <PlanHeader>Service Plan</PlanHeader>
      <SubParent>
        <LeftSection>
          <DataSection>
            <DatarowHeader>Virtual consultation</DatarowHeader>
            {/* Render the consultation services */}
            {consultationServices?.map((service) => (
              <Datarow key={service.medical_service_id}>
                <span>{service.service_name}</span>
                <DataValue
                  style={
                    !service.is_included
                      ? {
                          backgroundColor: "#F5F5F5",
                          color: "#808080",
                          borderColor: "#DDDDDD",
                        }
                      : {}
                  }
                >
                  {service.is_included ? "Included" : "Not Included"}
                </DataValue>
              </Datarow>
            ))}
            <DatarowHeader>Tests</DatarowHeader>
            {/* Render the test services */}
            {testServices?.map((service) => (
              <Datarow key={service.medical_service_id}>
                <span>{service.service_name}</span>
                <DataValue
                  style={
                    !service.is_included
                      ? {
                          backgroundColor: "#F5F5F5",
                          color: "#808080",
                          borderColor: "#DDDDDD",
                        }
                      : {}
                  }
                >
                  {service.is_included ? "Included" : "Not Included"}
                </DataValue>
              </Datarow>
            ))}
          </DataSection>
        </LeftSection>
        <RightSection>
          <ManagerInfo
            managerEmail={managerData?.[0]?.manager_email}
            managerName={managerData?.[0]?.manager_name}
            managerNumber={managerData?.[0]?.manager_number}
          />
        </RightSection>
      </SubParent>
    </Parent>
  );
};

export default ServicePLan;
