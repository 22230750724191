import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingCampsAction } from "../../store/actions";
import { campsSelector } from "../../store/slice/camps.slice";
import {
  Container,
  Header,
  ListDateTitle,
  ListItem,
  ListItemWrapper,
  ListLocationTitle,
  ListRegisterationTitle,
  ListRegisteredTitle,
  ListSection,
  ListWrapper,
  WrappedListValue,
} from "./style.components";

const UpcomingCamps = () => {
  const dispatch = useDispatch();
  const { camps } = useSelector(campsSelector);
  const [campsData, setCampsData] = useState(null);
  const fetchUpcomingCamps = useCallback(async () => {
    await dispatch(getUpcomingCampsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchUpcomingCamps();
  }, [fetchUpcomingCamps]);

  useEffect(() => {
    if (camps?.length > 0) {
      const grouping = _.groupBy(camps, (element) =>
        element.start_date.substring(0, 10)
      );
      const sections = _.map(grouping, (items, date) => ({
        date: date,
        camps: items,
      }));
      setCampsData(sections);
    }
  }, [camps]);

  return (
    <Container>
      <Header>Upcoming Camps</Header>
      {campsData?.length > 0 &&
        campsData.map((item, index) => {
          return (
            <ListWrapper>
              <ListSection>
                <ListDateTitle>
                  {moment(item?.date, "DD-MM-YYYY").format("DD-MMM-YYYY")}
                </ListDateTitle>
                <ListLocationTitle>Location</ListLocationTitle>
                <ListRegisterationTitle>
                  Max. Registration
                </ListRegisterationTitle>
                <ListRegisteredTitle>Registered</ListRegisteredTitle>
              </ListSection>
              {item?.camps?.length > 0 &&
                item.camps.map((subItem, subIndex) => {
                  const startTime = moment(
                    subItem?.start_date,
                    "DD-MM-YYYY hh:mm:ss"
                  ).format("h:mm A");
                  const endTime = moment(
                    subItem?.end_date,
                    "DD-MM-YYYY hh:mm:ss"
                  ).format("h:mm A");
                  return (
                    <ListItemWrapper>
                      <ListItem>{subItem?.service_name}</ListItem>
                      <ListItem>{`${startTime} - ${endTime}`}</ListItem>
                      <ListItem>{subItem?.venue}</ListItem>
                      <ListItem>{subItem?.max_size}</ListItem>
                      <ListItem>
                        <WrappedListValue>
                          {subItem?.registrant_count}
                        </WrappedListValue>
                      </ListItem>
                    </ListItemWrapper>
                  );
                })}
            </ListWrapper>
          );
        })}
    </Container>
  );
};

export default UpcomingCamps;
