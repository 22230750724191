import { CircularProgress } from "@mui/material";
import React from "react";
import { COLOR_VARIANT } from "../../../utilities/constants";
import { LoaderDiv } from "./style.components";

const Loader = ({ height, size, text }) => {
  return (
    <LoaderDiv height={height}>
      <div style={{ marginRight: "10px", color: COLOR_VARIANT }}>{text}</div>
      <CircularProgress size={size ? size : 30} />
    </LoaderDiv>
  );
};

export default Loader;
