import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const addEmployee = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.EMPLOYEE, data, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const getTotalEmployeesCount = async () => {
  try {
    const response = await apiClient.get(`${API_ENDPOINT.EMPLOYEE}/count`);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const getTotalActiveEmployeesCount = async () => {
  try {
    const response = await apiClient.get(
      `${API_ENDPOINT.EMPLOYEE}/count/active`
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const getEmployees = async (totalEmployees) => {
  try {
    const url = `${
      API_ENDPOINT.EMPLOYEE
    }/all?page=${0}&items_per_page=${totalEmployees}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const getEmployeeByNumber = async (payload) => {
  try {
    const response = await apiClient.post(
      `${API_ENDPOINT.EMPLOYEE}/search`,
      payload,
      true
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const deleteEmployeeById = async (employeeId) => {
  try {
    const response = await apiClient._delete(
      `${API_ENDPOINT.EMPLOYEE}/${employeeId}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const updateEmployeeById = async (employeeId, data) => {
  try {
    const response = await apiClient.put(
      `${API_ENDPOINT.EMPLOYEE}/${employeeId}`,
      data
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
export {
  addEmployee,
  deleteEmployeeById,
  getEmployeeByNumber,
  getEmployees,
  getTotalActiveEmployeesCount,
  getTotalEmployeesCount,
  updateEmployeeById,
};
