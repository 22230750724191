import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_VARIANT,
  DEVICE,
  FONT_FAMILY_GENERAL,
} from "../../utilities/constants";

export const LoginContainer = styled.div({
  backgroundColor: "#0099CC",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const LoginLogo = styled.div`
  margin-bottom: 60px;
  @media ${DEVICE.laptopL} {
    margin-bottom: 30px;
    img {
      width: 90%;
    }
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  overflow: hidden;
  @media ${DEVICE.laptopL} {
    justify-content: center;
    width: 90%;
  }
`;

export const LoginFormRight = styled.div`
  background-color: #ffffff;
  padding: 40px;
  @media ${DEVICE.laptopL} {
    border-radius: 12px;
  }
`;

export const FormMessage = styled.div({
  color: "#0099CC",
  display: "flex",
  alignItems: "center",
  marginBottom: "30px",
  fontSize: "28px",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const FormFieldSection = styled.div({
  display: "flex",
  flexDirection: "column",
  marginBottom: "30px",
});

export const FormLabel = styled.div({
  color: "#808080",
  fontSize: "16px",
  textAlign: "left",
  marginBottom: "10px",
});

export const FormFieldRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const CustomSelect = styled.select({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flexGrow: 1,
  minWidth: "50px",
  paddingLeft: "10px",
  marginRight: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});

export const CustomInput = styled.input({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flexGrow: 1,
  paddingLeft: "10px",
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
  marginRight: "10px",
});

export const CustomButton = styled.input({
  backgroundColor: COLOR_VARIANT,
  border: "none",
  textDecoration: "none",
  height: "53px",
  width: "100%",
  cursor: "pointer",
  color: "#FFF",
  borderRadius: "8px",
  fontSize: "16px",
});

export const ActionText = styled.div({
  color: "#7ABA56",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  flexGrow: 1,
  ":hover": {
    textDecoration: "underline",
  },
});

export const TCText = styled.div({
  textAlign: "left",
  fontSize: "13px",
  color: "#808080",
  marginBottom: "20px",
});

export const TCLink = styled.a({
  textDecoration: "none",
  fontSize: "13px",
  color: COLOR_PRIMARY,
  marginLeft: "5px",
});
