import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IDelete, IEditIcon, ISearch } from "../../../assets";
import { DeleteConfirmationModal } from "../../../components/layout";
import { SnackBar } from "../../../components/shared";
import { getBasicStatsAction } from "../../../store/actions";
import {
  deleteEmployeeByIdAction,
  getEmployeeByNumberAction,
  getEmployeesAction,
  getTotalEmployeesCountAction,
} from "../../../store/actions/employee.action";
import { dashboardSelector } from "../../../store/slice/dashboard.slice";
import { employeeSelector } from "../../../store/slice/employee.slice";
import {
  ActionImage,
  AddButton,
  Container,
  CountSeparator,
  DeleteButton,
  EditButton,
  EditDeleteWrapper,
  EmptyListMessage,
  Header,
  HeaderEmployeeCount,
  HeaderLeft,
  HeaderRight,
  HeaderWrapper,
  SearchButton,
  SearchImage,
  SearchInput,
  SearchWrapper,
  WrappedListValue,
} from "./style.components";

function createData(id, name, email, number) {
  return { id, name, email, number };
}

const EmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeSelectedForDelete, setEmployeeSelectedForDelete] =
    useState(null);

  //selectors
  const { totalEmployee, employees, isEmployeeDeleted, searchedEmployee } =
    useSelector(employeeSelector);

  const { basicStatsData } = useSelector(dashboardSelector);

  const fetchTotalEmployees = useCallback(async () => {
    await dispatch(getTotalEmployeesCountAction());
  }, [dispatch]);

  const fetchAllEmployees = useCallback(async () => {
    if (totalEmployee) {
      await dispatch(getEmployeesAction(totalEmployee));
    }
  }, [dispatch, totalEmployee]);

  useEffect(() => {
    fetchTotalEmployees();
  }, [fetchTotalEmployees]);

  const formulateEmployeesData = useCallback(() => {
    let data = [];
    employees.forEach((employee) => {
      const { id, name, email, number } = employee;
      data.push(createData(id, name, email, number));
    });
    setEmployeeData(data);
  }, [employees]);

  useEffect(() => {
    if (employees?.length > 0) {
      formulateEmployeesData();
    }
  }, [employees, formulateEmployeesData]);

  const onDelete = async () => {
    if (employeeSelectedForDelete) {
      setShowDeleteModal(false);
      await dispatch(deleteEmployeeByIdAction(employeeSelectedForDelete));
    }
  };

  const fetchBasicStats = useCallback(async () => {
    await dispatch(getBasicStatsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchBasicStats();
  }, [fetchBasicStats]);

  useEffect(() => {
    fetchAllEmployees();
  }, [isEmployeeDeleted, fetchAllEmployees]);

  const fetchEmployeeByNumber = useCallback(async () => {
    const payload = new FormData();
    payload.append("number", searchInput);
    await dispatch(getEmployeeByNumberAction(payload));
  }, [dispatch, searchInput]);

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchEmployeeByNumber();
    }, 2000);

    return () => clearTimeout(getData);
  }, [searchInput, fetchEmployeeByNumber]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell align="left">
          <b>Employee Name</b>
        </TableCell>
        <TableCell align="left">
          <b>Mobile no.</b>
        </TableCell>
        <TableCell align="left">
          <b>Email-Id</b>
        </TableCell>
        <TableCell align="right">
          <HeaderEmployeeCount>
            <b>Employees Added</b>{" "}
            <WrappedListValue>
              {basicStatsData?.[0]?.employee_count}
            </WrappedListValue>
            <CountSeparator>/</CountSeparator>
            <b>{basicStatsData?.[0]?.plan_size}</b>
          </HeaderEmployeeCount>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <Container>
      <HeaderWrapper>
        <HeaderLeft>
          <Header>Manage Employees</Header>
        </HeaderLeft>
        <HeaderRight>
          <SearchWrapper>
            <SearchInput
              placeholder="Search employee by mobile number"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <SearchButton>
              <SearchImage alt="search-image" src={ISearch} />
            </SearchButton>
          </SearchWrapper>
          <AddButton onClick={() => navigate("/add-employee")}>Add</AddButton>
        </HeaderRight>
      </HeaderWrapper>
      {!searchedEmployee && employeeData?.length > 0 && (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHeader />
            <TableBody>
              {employeeData.map((row) => {
                const { id, email, name, number } = row;
                return (
                  <TableRow key={id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{number}</TableCell>
                    <TableCell align="left">{email}</TableCell>
                    <TableCell>
                      <EditDeleteWrapper>
                        <EditButton
                          onClick={() =>
                            navigate("/edit-employee", {
                              state: { number: number },
                            })
                          }
                        >
                          <ActionImage src={IEditIcon} alt="edit" />
                          Edit
                        </EditButton>
                        <DeleteButton
                          onClick={() => {
                            setEmployeeSelectedForDelete(id);
                            setShowDeleteModal(true);
                          }}
                        >
                          <ActionImage src={IDelete} alt="delete" />
                          Delete
                        </DeleteButton>
                      </EditDeleteWrapper>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {searchedEmployee && searchedEmployee?.length > 0 && (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHeader />
            <TableBody>
              {searchedEmployee.map((row) => {
                const { id, email, name, number } = row;
                if (id) {
                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{number}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell>
                        <EditDeleteWrapper>
                          <EditButton
                            onClick={() =>
                              navigate("/edit-employee", {
                                state: { number: number },
                              })
                            }
                          >
                            <ActionImage src={IEditIcon} alt="edit" />
                            Edit
                          </EditButton>
                          <DeleteButton
                            onClick={() => {
                              setEmployeeSelectedForDelete(id);
                              setShowDeleteModal(true);
                            }}
                          >
                            <ActionImage src={IDelete} alt="delete" />
                            Delete
                          </DeleteButton>
                        </EditDeleteWrapper>
                      </TableCell>
                    </TableRow>
                  );
                }
                return <EmptyListMessage>No records found</EmptyListMessage>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DeleteConfirmationModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={onDelete}
      />
      <SnackBar />
    </Container>
  );
};
export default EmployeeList;
