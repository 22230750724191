import styled from "styled-components";
import { COLOR_SECONDARY, DEVICE } from "../../../utilities/constants";

export const BasicInfo = styled.div`
  background-color: ${COLOR_SECONDARY};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 25px 0px;
  flex-grow: 1;
`;

export const BasicInfoWrapper = styled.div`
  background-color: ${COLOR_SECONDARY};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 32px #2477b314;
  flex-grow: 1;
`;

export const InfoWrapper = styled.div`
  display: flex;
  column-gap: 50px;
  margin-bottom: 40px;
`;

export const SingleCountBlock = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  borderRight: props.borderRight ? "1px solid #D7EFF7" : "none",
  "@media": {
    [DEVICE.laptop]: {
      flexDirection: "column",
      borderRight: "none",
    },
  },
}));

export const SingleCountBlockDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${DEVICE.laptop} {
    align-items: center;
    margin-top: 10px;
  }
`;

export const CountLabel = styled.div`
  color: #808080;
  font-size: 16px;
  @media ${DEVICE.laptop} {
    font-size: 12px;
  }
`;

export const CountImage = styled.img`
  @media ${DEVICE.laptop} {
    height: 70px;
  }
`;

export const CountValue = styled.div`
  font-size: 35px;
  @media ${DEVICE.mobileL} {
    font-size: 15px;
  }
`;
