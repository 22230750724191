import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
`;
export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  background: transparent;
  cursor: pointer;
`;
export const CloseButtonWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  background: transparent;
`;
export const DeleteButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: #cc0000;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
`;
export const CancelButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
`;
