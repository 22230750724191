import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getBasicStats = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.COMPANY_PLAN_DETAILS);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getScheduledCamps = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.SCHEDULED_CAMPS);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export { getBasicStats, getScheduledCamps };
