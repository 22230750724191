import { createSlice } from "@reduxjs/toolkit";
import { generateOTPAction, signoutAction, verifyOTPAction } from "../actions";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  userDetails: null,
  isOTPVerified: null,
  isLoggedOut: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // login: (state, action) => {},
  },
  extraReducers: (builder) => {
    // generate otp
    builder.addCase(generateOTPAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateOTPAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = true;
    });
    builder.addCase(generateOTPAction.rejected, (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    });
    // verify otp
    builder.addCase(verifyOTPAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOTPAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isOTPVerified = true;
      state.userDetails = payload?.token || null;
    });
    builder.addCase(verifyOTPAction.rejected, (state) => {
      state.isLoading = false;
      state.isOTPVerified = false;
    });
    // signout
    builder.addCase(signoutAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signoutAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isLoggedOut = true;
      state.isAuthenticated = false;
      state.userDetails = null;
      state.isOTPVerified = null;
    });
    builder.addCase(signoutAction.rejected, (state) => {
      state.isLoading = false;
      state.isLoggedOut = false;
    });
  },
});

// export const { login } = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;
