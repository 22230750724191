import React from "react";
import { IEmail, IPhone } from "../../../assets";
import {
  ContactCard,
  DetailName,
  DetailText,
  Details,
  Heading,
  ManagerDetails,
} from "./style.components";

const ManagerInfo = ({ managerName, managerEmail, managerNumber }) => {
  return (
    <ContactCard>
      <Heading>Relationship Manager</Heading>
      <Details>
        <DetailName>Mr. {managerName}</DetailName>
        <DetailText>
          <ManagerDetails src={IEmail} />
          {managerEmail}
        </DetailText>
        <DetailText>
          <ManagerDetails src={IPhone} />
          {managerNumber}
        </DetailText>
      </Details>
    </ContactCard>
  );
};

export default ManagerInfo;
