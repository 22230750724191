import styled from "styled-components";
import { DEVICE } from "../../../utilities/constants";

export const ContactCard = styled.div`
  width: 394px;
  height: 192px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  background: #0099cc;
  border-radius: 12px 12px 0px 0px;
  height: 59px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  padding-left: 40px;
  text-transform: uppercase;
  font-size: 14px;
`;

export const Details = styled.div`
  background: #ebf7fb;
  border-radius: 0px 0px 12px 12px;
  padding: 20px 20px 30px 40px;
`;

export const DetailText = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #404040;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const DetailName = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: #0099cc;
`;

export const DatarowHeader = styled.p`
  font-size: 16px;
  color: #0099cc;
`;

export const ManagerDetails = styled.img`
  height: 12px;
  @media ${DEVICE.tablet} {
    height: 12px;
  }
`;
