import { createSlice } from "@reduxjs/toolkit";
import { getUpcomingCampsAction } from "../actions";

const initialState = {
  isLoading: false,
  camps: null,
};

const campsSlice = createSlice({
  name: "camps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUpcomingCampsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUpcomingCampsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.camps = payload?.result;
    });
    builder.addCase(getUpcomingCampsAction.rejected, (state) => {
      state.isLoading = false;
      state.camps = null;
    });
  },
});

export const campsSelector = (state) => state.camps;
export default campsSlice.reducer;
