import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 32px #2477b314;
  border-radius: 12px;
  opacity: 1;
  margin-left: 40px;
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const Header = styled.div`
  font-size: 30px;
  border-bottom: 1px solid #d7eff7;
  color: #0099cc;
  margin-bottom: 20px;
  padding: 20px;
`;

export const ListWrapper = styled.div``;
export const ListSection = styled.div`
  height: 50px;
  background-color: #ebf7fb;
  padding-left: 20px;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
`;
export const ListDateTitle = styled.div`
  display: flex;
  flex: 0.4;
  font-weight: bold;
  color: #0099cc;
  font-size: 16px;
`;
export const ListLocationTitle = styled.div`
  display: flex;
  flex: 0.2;
  font-size: 14px;
  color: #808080;
`;
export const ListRegisterationTitle = styled.div`
  display: flex;
  flex: 0.2;
  font-size: 14px;
  color: #808080;
  justify-content: center;
`;
export const ListRegisteredTitle = styled.div`
  display: flex;
  flex: 0.2;
  font-size: 14px;
  color: #808080;
  justify-content: center;
`;
export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebf7fb;
  padding-left: 20px;
  &:last-child {
    border: none;
  }
`;
export const ListItem = styled.div`
  display: flex;
  flex: 0.2;
  font-size: 13px;
  &:first-child {
    font-weight: bold;
  }
  &:nth-child(4) {
    justify-content: center;
  }
  &:last-child {
    justify-content: center;
  }
`;
export const WrappedListValue = styled.div`
  width: 40px;
  height: 25px;
  border: 1px solid #d7eff7;
  border-radius: 5px;
  color: #0099cc;
  font-weight: bold;
  background-color: #ebf7fb;
  justify-content: center;
  align-items: center;
  display: flex;
`;
