import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BasicCount, DashboardInfo } from "../../components/layout";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { MainContainer } from "./style.components";

const Home = () => {
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  return (
    <MainContainer>
      <BasicCount />
      <DashboardInfo />
    </MainContainer>
  );
};
export default Home;
