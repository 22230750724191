import { createAsyncThunk } from "@reduxjs/toolkit";
import { getManager, getServicePlan } from "../../api";

export const getServicePlanAction = createAsyncThunk(
  "service-plan",
  async (_, thunkAPI) => {
    try {
      const response = await getServicePlan();
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        error || "Error while fetching service plan"
      );
    }
  }
);

export const getManagerAction = createAsyncThunk(
  "manager",
  async (_, thunkAPI) => {
    try {
      const response = await getManager();
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error || "Error while fetching manager");
    }
  }
);
