import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ILogoHorizontalWhite, IResend } from "../../assets";

import { generateOTPAction } from "../../store/actions";
import { COLOR_VARIANT } from "../../utilities/constants";

import { SnackBar } from "../../components/shared";
import { verifyOTPAction } from "../../store/actions/auth.action";
import { authSelector } from "../../store/slice/auth.slice";
import {
  ActionText,
  CustomButton,
  CustomInput,
  CustomSelect,
  FormFieldRow,
  FormFieldSection,
  FormLabel,
  FormMessage,
  LoginContainer,
  LoginForm,
  LoginFormRight,
  LoginLogo,
  TCLink,
  TCText,
} from "./style.components";

const Login = () => {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  const { isLoading, isOTPVerified, userDetails } = useSelector(authSelector);

  useEffect(() => {
    if (isOTPVerified && userDetails?.access_token) {
      navigate("/home");
    }
  }, [isOTPVerified, userDetails, navigate]);

  const handleSendOtpClick = () => {
    sendOtpToMobileNumber();
  };

  const handleLoginClick = () => {
    validateOtp();
  };

  const sendOtpToMobileNumber = async () => {
    if (!mobileNumber) {
      return;
    }
    const payload = new FormData();
    payload.append("number", `${countryCode}${mobileNumber}`);
    await dispatch(generateOTPAction(payload));
  };

  const validateOtp = async () => {
    const payload = new FormData();
    payload.append("number", `${countryCode}${mobileNumber}`);
    payload.append("otp", otp);
    await dispatch(verifyOTPAction(payload));
  };

  return (
    <>
      <LoginContainer>
        <LoginLogo>
          <img src={ILogoHorizontalWhite} alt="logo" />
        </LoginLogo>
        <LoginForm>
          <LoginFormRight>
            <FormMessage>
              Company Portal
              <div style={{ marginLeft: "20px" }}>
                {isLoading ? (
                  <CircularProgress
                    size={18}
                    style={{ color: COLOR_VARIANT }}
                  />
                ) : null}
              </div>
            </FormMessage>
            <FormFieldSection>
              <FormLabel>Mobile no.</FormLabel>
              <FormFieldRow>
                <CustomSelect
                  value={countryCode}
                  onChange={(event) => setCountryCode(event.target.value)}
                >
                  <option value="+91">+91</option>
                  <option value="+44">+44</option>
                  <option value="+212">+212</option>
                </CustomSelect>
                <CustomInput
                  value={mobileNumber}
                  onChange={(event) => setMobileNumber(event.target.value)}
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                ></CustomInput>
                <ActionText onClick={handleSendOtpClick}>Send OTP</ActionText>
              </FormFieldRow>
            </FormFieldSection>
            <FormFieldSection>
              <FormLabel>Enter OTP</FormLabel>
              <FormFieldRow>
                <CustomInput
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                  type={"number"}
                ></CustomInput>
                <ActionText onClick={handleSendOtpClick}>
                  {" "}
                  <img
                    src={IResend}
                    style={{ marginRight: "4px" }}
                    alt="resend"
                  />{" "}
                  Resend
                </ActionText>
              </FormFieldRow>
            </FormFieldSection>
            <TCText>
              By logging in you agree to our
              <TCLink href="https://hemehealth.io/Privacy" target="_blank">
                Terms and Conditions
              </TCLink>
            </TCText>
            <FormFieldSection>
              <CustomButton
                onClick={handleLoginClick}
                value="Login"
                type="button"
              />
            </FormFieldSection>
          </LoginFormRight>
        </LoginForm>
      </LoginContainer>
      <SnackBar />
    </>
  );
};
export default Login;
