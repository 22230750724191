import { createSlice } from "@reduxjs/toolkit";
import { getManagerAction, getServicePlanAction } from "../actions";

const initialState = {
  servicePlanData: null,
  managerData: null,
  isLoading: null,
};

const servicePlanSlice = createSlice({
  name: "servicePlan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServicePlanAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServicePlanAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.servicePlanData = payload?.result;
    });
    builder.addCase(getServicePlanAction.rejected, (state) => {
      state.isLoading = false;
      state.servicePlanData = null;
    });
    builder.addCase(getManagerAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getManagerAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.managerData = payload?.result;
    });
    builder.addCase(getManagerAction.rejected, (state) => {
      state.isLoading = false;
      state.managerData = null;
    });
  },
});

export const servicePlanSelector = (state) => state.servicePlan;
export default servicePlanSlice.reducer;
