import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import {
  ButtonWrapper,
  CancelButton,
  CloseButton,
  CloseButtonWrapper,
  DeleteButton,
} from "./style.components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DeleteConfirmationModal = ({ open, handleClose, handleDelete }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CloseButtonWrapper>
          <CloseButton onClick={handleClose}>X</CloseButton>
        </CloseButtonWrapper>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to delete ?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          The action cannot be undone once deleted.
        </Typography>
        <ButtonWrapper>
          <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
        </ButtonWrapper>
      </Box>
    </Modal>
  );
};
export default DeleteConfirmationModal;
