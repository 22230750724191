import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import { getScheduledCampsActions } from "../../../store/actions/dashboard.action";
import { dashboardSelector } from "../../../store/slice/dashboard.slice";
import { Loader } from "../../shared";
import ManagerInfo from "../manager-info";
import {
  DasboardParent,
  DataSection,
  DataValue,
  Datarow,
  DatarowThird,
  LeftSection,
  PlanDetailsButton,
  PlanHeader,
  RightSection,
} from "./style.components";

const DasboardInfo = () => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, scheduledCampsData, basicStatsData } =
    useSelector(dashboardSelector);

  const fetchScheduledCamps = useCallback(async () => {
    await dispatch(getScheduledCampsActions());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      fetchScheduledCamps();
    }
  }, [isAuthenticated, navigate, fetchScheduledCamps]);

  return (
    <DasboardParent>
      {!isLoading ? (
        <>
          <LeftSection>
            <PlanHeader>Your Plan</PlanHeader>
            <DataSection>
              <Datarow>
                <span>Start Date</span>
                <DataValue>
                  {moment(basicStatsData?.[0]?.start_date, "DD-MM-YYYY").format(
                    "DD-MMM-YYYY"
                  )}
                </DataValue>
              </Datarow>
              <Datarow>
                <span>End Date</span>
                <DataValue>
                  {moment(basicStatsData?.[0]?.end_date, "DD-MM-YYYY").format(
                    "DD-MMM-YYYY"
                  )}
                </DataValue>
              </Datarow>
              <DatarowThird>
                <span>Scheduled Camps</span>
                <DataValue>{scheduledCampsData?.[0]?.total}</DataValue>
              </DatarowThird>
              <PlanDetailsButton onClick={() => navigate("/service-plan")}>
                Plan Details
              </PlanDetailsButton>
            </DataSection>
          </LeftSection>
          <RightSection>
            <ManagerInfo
              managerEmail={basicStatsData?.[0]?.manager_email}
              managerName={basicStatsData?.[0]?.manager_name}
              managerNumber={basicStatsData?.[0]?.manager_number}
            />
          </RightSection>
        </>
      ) : (
        <Loader height="100px" />
      )}
    </DasboardParent>
  );
};

export default DasboardInfo;
