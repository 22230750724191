import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 32px #2477b314;
  border-radius: 12px;
  opacity: 1;
  margin-left: 40px;
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d7eff7;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 30px;
  color: #0099cc;
`;
export const SearchInput = styled.input`
  font-size: 14px;
  outline: none;
  flex: 1;
  background: transparent;
  height: 35px;
  border: none;
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex: 0.4;
`;
export const HeaderRight = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: space-between;
  align-items: center;
`;

export const AddButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 40px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
`;
export const EditButton = styled.button`
  color: #7aba57;
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;
export const DeleteButton = styled.button`
  color: #cc0100;
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0;
  
`;
export const SearchButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
export const SearchImage = styled.img`
  width: 25px;
  height: 25px;
`;
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ebf7fb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px 12px;
  width: 400px;
  height: 35px;
  margin-top: 10px;
  border-style: hidden;
`;
export const ActionImage = styled.img`
  margin-right: 5px;
`;
export const EmptyListMessage = styled.div`
  margin-top: 10px;
  color: red;
`;
export const HeaderEmployeeCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
export const WrappedListValue = styled.div`
  width: 40px;
  height: 25px;
  border: 1px solid #d7eff7;
  border-radius: 5px;
  color: #0099cc;
  font-weight: bold;
  background-color: #ebf7fb;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
`;
export const CountSeparator = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;
`;
export const EditDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
