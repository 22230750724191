import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackBar } from "../../../components/shared";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import {
  getEmployeeByNumberAction,
  updateEmployeeByIdAction,
} from "../../../store/actions/employee.action";
import { employeeSelector } from "../../../store/slice/employee.slice";
import {
  Container,
  ErrorMessage,
  Header,
  InputWrapper,
  Label,
  LeftSection,
  MobileInputWrapper,
  MobilePlaceholder,
  RightSection,
  SubHeader,
  SubmitButton,
  TextInput,
  Wrapper,
} from "./style.components";

const EditEmployee = () => {
  const {
    state: { number },
  } = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { searchedEmployee } = useSelector(employeeSelector);

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    name: "",
    mobileNumber: "",
  });

  const fetchEmployeeByNumber = useCallback(async () => {
    const payload = new FormData();
    payload.append("number", number);
    await dispatch(getEmployeeByNumberAction(payload));
  }, [dispatch, number]);

  useEffect(() => {
    if (searchedEmployee?.length > 0) {
      const { id, name, number, email } = searchedEmployee[0];
      const modifiedNumber = number.toString().replace("+91", "");
      setName(name);
      setEmailId(email);
      setMobileNumber(modifiedNumber);
      setEmployeeId(id);
    }
  }, [searchedEmployee]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      fetchEmployeeByNumber();
    }
  }, [fetchEmployeeByNumber, navigate, isAuthenticated]);

  const onEditEmployee = async () => {
    if (!employeeId) {
      return;
    }
    if (!name) {
      setErrorMessage({
        ...errorMessage,
        name: "Name is required",
      });
      return;
    }
    if (!mobileNumber) {
      setErrorMessage({
        ...errorMessage,
        mobileNumber: "Mobile number is required",
      });
      return;
    }
    const data = {
      name,
      number: `+91${mobileNumber}`,
      email: emailId,
      employeeId,
    };
    await dispatch(updateEmployeeByIdAction(data));
  };

  return (
    <Container>
      <Header>Edit Employees</Header>
      <Wrapper>
        <LeftSection>
          <SubHeader>Employee Details</SubHeader>
          <InputWrapper>
            <Label>Name</Label>
            <TextInput
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage({
                  ...errorMessage,
                  name: "",
                });
              }}
            />
            {errorMessage?.name && (
              <ErrorMessage>{errorMessage?.name}</ErrorMessage>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label>Mobile No.</Label>
            <MobileInputWrapper>
              <MobilePlaceholder>+91</MobilePlaceholder>
              <TextInput
                disabled
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                  setErrorMessage({
                    ...errorMessage,
                    mobileNumber: "",
                  });
                }}
                type="number"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  width: 365,
                  paddingLeft: 0,
                }}
              />
            </MobileInputWrapper>
            {errorMessage?.mobileNumber && (
              <ErrorMessage>{errorMessage?.mobileNumber}</ErrorMessage>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label>Email-Id (Optional)</Label>
            <TextInput
              type="email"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
            />
          </InputWrapper>
          <SubmitButton onClick={onEditEmployee}>Update</SubmitButton>
        </LeftSection>
        <RightSection></RightSection>
      </Wrapper>
      <SnackBar />
    </Container>
  );
};

export default EditEmployee;
