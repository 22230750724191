import { useEffect } from "react";
import { useStore } from "react-redux";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { useSelector } from "react-redux";
import { setupAxiosInstance } from "../api/api-client";
import {
  AddEmployee,
  EditEmployee,
  EmployeeList,
  Home,
  Login,
  Root,
  ServicePlan,
  UpcomingCamps,
} from "../containers/";
import { authSelector } from "../store/slice/auth.slice";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "service-plan",
        element: <ServicePlan />,
      },
      {
        path: "add-employee",
        element: <AddEmployee />,
      },
      {
        path: "manage-employees",
        element: <EmployeeList />,
      },
      {
        path: "upcoming-camps",
        element: <UpcomingCamps />,
      },
      {
        path: "edit-employee",
        element: <EditEmployee />,
      },
    ],
  },
]);

const RootRouter = () => {
  const { isOTPVerified, userDetails } = useSelector(authSelector);
  const store = useStore();
  setupAxiosInstance(store);
  useEffect(() => {
    if (isOTPVerified && userDetails?.access_token) {
      redirect("/");
    } else {
      redirect("/login");
    }
  }, [isOTPVerified, userDetails]);

  return <RouterProvider router={router} />;
};

export default RootRouter;
